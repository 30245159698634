@import '../../../node_modules/@mapado/makeup/src/styles/base/_base_variables.scss';
.mpd-seating__seating,
.mpd-seating__event-blocker {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.mpd-seating__svg-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  position: relative;
}

.mpd-seating__event-blocker--enabled {
  pointer-events: none;
  opacity: 0.6;
}

.mpd-seating__alert {
  margin: 10px auto 0 auto;
  width: fit-content;
  background-color: $secondaryWhite;
  box-shadow: none;
}
